<template>
  <div class="content mutiControls">
    <div class="top">
      <span
        @click.stop="goToPage"
        class="ponter">
        <i
          v-if="!isRcIframe"
          :title="$t('lang.ProfileBack')"
          class="iconfont icon-back"></i> {{ this.$t('lang.MultiControl') }}
      </span>
    </div>
    <div class="timeLockModule body-left clearfix">
      <div class="rlist">
        <drop-menu-select
          :data="rList"
          :useDefault="false"
          :keyArray="['peerId', 'name', 'isChecked']"
          :title="$t('lang.selectReceiver')"
          :callback="showOrHideVideo"
          :returnDataInfo="true"
          @openList='getRList'></drop-menu-select>
      </div>
      <div class="timelockTips">
        {{$t('lang.syncTitle')}}
      </div>
      <div class="checkModule">
        <el-checkbox
          v-model="synchronizedObj.synchronized"
          @change="setTimelock">{{$t('lang.TimeLock')}}</el-checkbox>
        <div
          class="failedTips"
          v-show="synchronizedObj.synchronized && failedTxt"
          :title="failedTxt">
          <span class="iconfont icon-warning2"></span>&nbsp;&nbsp;{{ failedTxt }}
        </div>
      </div>
      <div class="settingItem">
        <div class="box-top clearfix"> {{$t('lang.Bitrate')}}(kbps) </div>
        <div class="sliderBox">
          <el-slider
            @change="dragEnd($event,'bitrate')"
            :disabled="!synchronizedObj.synchronized || !synchronizedObj.canSetOptions"
            v-model="synchronizedObj.bitrate"
            :min="100"
            :max="10240"></el-slider>
          <el-input
            class="multiInput"
            :disabled="!synchronizedObj.synchronized || !synchronizedObj.canSetOptions"
            v-model="synchronizedObj.inputBitrate"
            @focus="changeFreash(false)"
            @blur="changeFreash(true)"
            @keyup.enter.native="changeVal('bitrate')"
          ></el-input>
        </div>
      </div>
      <div class="settingItem">
        <div class="box-top clearfix"> {{$t('lang.Delay')}}(second) </div>
        <div class="sliderBox">
          <el-slider
            @change="dragEnd($event,'delay')"
            :disabled="!synchronizedObj.synchronized || !synchronizedObj.canSetOptions"
            v-model="synchronizedObj.delay"
            :min="0.5"
            :max="50"></el-slider>
          <el-input
            class="multiInput"
            :disabled="!synchronizedObj.synchronized || !synchronizedObj.canSetOptions"
            v-model="synchronizedObj.inputDelay"
            @focus="changeFreash(false)"
            @blur="changeFreash(true)"
            @keyup.enter.native="changeVal('delay')"></el-input>
        </div>
      </div>
    </div>
    <div class="rtcVideoModule body-right">
      <div>
        <div
          class="rtcBox"
          v-for="index in 12"
          :key='index'
          :class='{noBoreder:selectReceiver[index-1]}'>
          <p
            v-if="index == 1 && selectReceiver.length == 0"
            class="mainText">Main time source</p>
        </div>
      </div>
      <div class='videoListBox'>
        <div
          class="rtcBox"
          v-for="(item,index) in selectReceiver"
          :key='item'
          :class='{noBoreder:selectReceiver[index]}'>
          <rtc-video-item
            class="wh_parent"
            :page="pn"
            :synchronized="synchronizedObj.synchronized"
            :rid="selectReceiver[index]"
            @saveUserBehavior="saveUserBehavior"
            :synchronizStatus="syncStatusList"
            :socketItemData="rListInfo[selectReceiver[index]]"
            :canSetTimelockRList="lockedObj.receiver"
            :firstOne="index == 0"></rtc-video-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import { mapState, mapMutations } from 'vuex'
import DropMenuSelect from '@/components/dropMenuSelect'
import RtcVideoItem from './mutRtcVideoItem'

import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import cloneDeep from 'lodash.clonedeep'
import { handleTimeLockTips } from '@/assets/js/timeLockError'

export default {
  props: {
    rid: {
      type: String,
      default: ''
    },
    show: Object,
    pn: {
      type: String,
      default: 'hm'
    }
  },
  data () {
    return {
      rList: [], // 接口返回的所有R的信息，需要实时更新
      allRList: [], // websocket返回的所有R信息
      allRId: [], // 所有R的id的集合，供websocket作为参数
      synchronizedObj: {
        synchronized: false,
        delay: 2,
        bitrate: 5000,
        canSetOptions: false,
        inputBitrate: 5000,
        inputDelay: 2
      },
      selectReceiver: [],
      lockedObj: {
        receiver: [],
        pack: []
      },
      params: {
        living: true,
        online: true,
        offline: true,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        onlineFirst: false,
        liveFirst: true
      },
      initCreateWs: true,
      syncStatusList: [],
      rListInfo: {},
      isFreash: true,
      tRInfoMap: {},
      selectRIng: false,		// 用来记录当前是否是在选择R
      selectRTimer: null,
      behaviorList: [],
      isRcIframe: false,
      otherOccurDevice: [],
      syncDeviceList: [],
      failedTxt: ''
    }
  },
  components: {
    DropMenuSelect,
    RtcVideoItem
  },
  computed: {
    ...mapState({
      State: state => state,
      ReceiverInfoObj: state => state.ReceiverInfoObj,
      GetKernelStateObj: state => state.GetKernelStateObj,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    }),
    ...mapState('common', {
      States: state => state,
      Webrtc: state => state.webrtc
    })
  },
  async created () {
    await this.getRList()
    this.userBehaviorWs()
  },
  watch: {
    selectReceiver (val, oldVal) {
      if (val && val.length) {
        if (!oldVal || !this.arraysHaveSameItems(val, oldVal)) this.getTimeLockStatus()
      } else {
        this.otherOccurDevice = []
        this.syncDeviceList = []
        this.failedTxt = ''
      }
    }
  },
  mounted () {
    this.setMenuStyle()
  },
  methods: {
    ...mapMutations('common', [
      'CURRENT_RINFO',
      'DEVICELIST_VOLUMECHARTDATA',
      'DEVICELIST_SLOTINFODATA',
      'DEVICELIST_RECEIVERDATADATA',
      'DEVICELIST_CURRENTRINFODATA',
      'DEVICELIST_SETINFODATA'
    ]),
    setMenuStyle () {
      if ($('#navCommon .itemMenu').length) {
        const menuObj = $('#navCommon .itemMenu')
        if (!menuObj.hasClass('is-active')) {
          const timeLockHisRouter = localStorage.getItem('timeLockHisRouter')
          if (timeLockHisRouter) {
            $('#navCommon .itemMenu').each((index, element) => {
              if ($(element).attr('datakey') == timeLockHisRouter) {
                $(element).addClass('is-active')
              }
            })
          }
        }
      } else {
        setTimeout(() => { this.setMenuStyle() }, 300)
      }
    },
    arraysHaveSameItems (array1, array2) {
      if (array1.length !== array2.length) return false

      const sortedArray1 = array1.slice().sort()
      const sortedArray2 = array2.slice().sort()

      for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) return false
      }

      return true
    },
    getTimeLockStatus () {
      this.axios.get(`/ccp/tvucc-device/timeLock/checkTimeLockStatus?peerIds=${this.selectReceiver.concat(this.lockedObj.pack).join(',')}`)
        .then(res => {
          if (res.data.errorCode == '0x0') {
            const currentUser = localStorage.getItem('userId')
            const resultArr = res.data.result || []
            this.otherOccurDevice = []
            this.syncDeviceList = []
            resultArr.forEach(v => {
              this.syncDeviceList.push(v)
              if (v.userId != currentUser) this.otherOccurDevice.push(v)
            })
          }
        }).catch(() => {})
    },
    goToPage () {
      const hisRouter = localStorage.getItem('timeLockHisRouter') || 'mr'
      this.$router.push({ path: `/${hisRouter}` }).catch(() => {})
    },
    // 查询用户行为记录，实时更新timeLock信息
    userBehaviorWs () {
      if (!this.pageRequest) return
      this.pageRequest.send({
        message: {
          type: 'timeLockMultiR',
          module: 'timeLockMultiR',
          data: {}
        },
        time: 1000,
        key: 'timeLockMultiR',
        success: res => {
          if (res.result.multiR) {
            this.behaviorList = JSON.parse(res.result.multiR)
          }
          this.getRListStatus(cloneDeep(this.behaviorList))		// 获取R列表状态
          if (!this.isFreash) return // 正在
          this.synchronizedObj.canSetOptions = res.result.synchronized === 'true'
          this.synchronizedObj.synchronized = res.result.synchronized === 'true'
          this.synchronizedObj.bitrate = res.result.liveParam ? Number(JSON.parse(res.result.liveParam).bitrate) : 5000
          this.synchronizedObj.delay = res.result.liveParam ? Number(((JSON.parse(res.result.liveParam).delay) / 1000).toFixed(1)) : 2
          this.synchronizedObj.inputBitrate = res.result.liveParam ? Number(JSON.parse(res.result.liveParam).bitrate) : 5000
          this.synchronizedObj.inputDelay = res.result.liveParam ? Number(((JSON.parse(res.result.liveParam).delay) / 1000).toFixed(1)) : 2
          if (this.synchronizedObj.synchronized) this.getTimelockSyncStatus()
        }
      })
    },
    // 定时获取timeLock的R以及T的lock状态
    getTimelockSyncStatus () {
      this.pageRequest.send({
        message: {
          type: 'checkDevieTimeLockStatus',
          module: 'checkDevieTimeLockStatus',
          data: this.lockedObj.receiver.concat(this.lockedObj.pack).join(',')
        },
        time: 1000,
        isOnce: true,
        key: 'checkDevieTimeLockStatus',
        success: res => {
          const syncArr = res.result || []
          const failedDevice = []
          const pendingDevice = []
          syncArr.forEach(m => {
            if (m.status == 'failed') {
              const item = this.syncDeviceList.filter(n => n.peerId == m.peerId)
              if (item.length) failedDevice.push(item[0].name)
            }
            if (m.status != 'failed' && m.status != 'success') {
              const item = this.syncDeviceList.filter(n => n.peerId == m.peerId)
              if (item.length) pendingDevice.push(item[0].name)
            }
          })
          this.failedTxt = failedDevice.length ? this.$t('lang.failedDevice', { device: failedDevice.join(',') }) : ''
          this.failedTxt = pendingDevice.length ? this.$t('lang.pendingDevice', { device: pendingDevice.join(',') }) : ''
          this.syncStatusList = res.result
        }
      })
    },
    stopGetRInfo () {
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', 'getReceiverInfo')
        this.$createWebsocket.pageRWebsocket('stop', 'getQueryKennel')
        this.$createWebsocket.pageRWebsocket('stop', 'getAdapterInfo')
        this.$createWebsocket.pageRWebsocket('stop', 'getSettingInfo')
      }
    },
    // 获取R的实时状态
    getRListStatus (userData) {
      if (!this.pageRequest) return
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'rList_info',
          data: { peerIds: this.allRId.join() }
        },
        isOnce: true,
        key: 'rList_info',
        success: res => {
          this.allRList = res.result
          this.getAllRid(userData, res.result)
        }
      })
    },
    getAllRid (behaviorList, rList) {
      if (!behaviorList || this.selectRIng) return
      const receiverArr = []
      this.lockedObj.receiver = []
      this.lockedObj.pack = []
      behaviorList.map(item => {
        const obj = rList.filter(v => v.peerId == item.pid)[0]
        if (obj && !obj.isChecked) {
          obj.isChecked = true
          receiverArr.push(obj.peerId)
          const otherOccurItem = this.otherOccurDevice.filter(m => m.peerId == obj.peerId)
          obj.userEmail = otherOccurItem.length ? otherOccurItem[0].email : ''
          this.rListInfo[obj.peerId] = obj
        }
        if (obj && obj.status == 2) {
          this.lockedObj.receiver.push(obj.peerId)
          this.lockedObj.pack.push(obj.livePeerId)
          const otherOccurItem = this.otherOccurDevice.filter(m => m.peerId == obj.livePeerId)
          obj.packUserEmail = otherOccurItem.length ? otherOccurItem[0].email : ''
          this.tRInfoMap[obj.livePeerId] = obj.peerId
        }
      })
      this.selectReceiver = receiverArr
      // 更改R下拉列表中的R状态
      let rListObj = {}
      rList.map(v => {
        rListObj[v.peerId] = JSON.parse(JSON.stringify(v))
      })
      this.rList.map((v, i) => {
        if (rListObj[v.peerId]) {
          this.$set(this.rList, i, rListObj[v.peerId])
        }
      })
      /*
      * 停掉后面发送获取R信息的websocket,重新创建新的websocket去获取数据
      * 原因是旧的websocket只获取当前R的信息,不包含所有的.
      */
      if (this.initCreateWs && this.selectReceiver.length > 0) {
        this.initCreateWs = false
        this.stopGetRInfo()
      }
      this.createNewRWebsocket()
    },
    showOrHideVideo (obj, flag, list, selectpeVal) {
      const lastPeerId = list[list.length - 1]
      const item = this.allRList.filter(v => v.peerId == lastPeerId)
      if (list.length > this.selectReceiver.length && this.synchronizedObj.synchronized && item.length && item[0].status != '2' || list.length == this.selectReceiver.length) return
      if (list.length > 12) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.maxMultiControlNumber')
        })
        return
      }
      this.selectRIng = true
      // google adAnalytice
      if (this.pn === 'home') {
        if (this.selectReceiver.length > list.length) {
          adAnalytice('cancelSelectTimeLockReceiverByDropHMC')
        } else {
          adAnalytice('selectTimeLockReceiverByDropHM')
        }
      } else {
        if (this.selectReceiver.length > list.length) {
          adAnalytice('cancelSelectTimeLockReceiverByDropRC')
        } else {
          adAnalytice('selectTimeLockReceiverByDropRC')
        }
      }
      const operateObj = this.compareList(list)
      if (this.synchronizedObj.synchronized) {
        // 操作时如果timeLock开启，需要等接口返回成功才能加入新的R，并记录用户行为记录
        this.updateTimeLock(operateObj)
      } else {
        // 操作时若未开启timeLock，直接更改选中的设备并记录用户行为记录
        this.selectReceiver = this.selectReceiver.concat(operateObj.addPeerIds)
        this.goToBehavior()
      }
    },
    updateTimeLock (obj, tag) {
      if (!obj.addPeerIds.length && !obj.deletePeerIds.length && !obj.deleteMainReceiver) return
      let addString = []
      if (obj.addPeerIds.length && !tag) {
        obj.addPeerIds.forEach(item => {
          const socketRItem = this.allRList.filter(v => v.peerId == item)
          if (socketRItem.length && socketRItem[0].status == '2') {
            addString.push(item)
            addString.push(socketRItem[0].livePeerId)
          }
        })
        if (!addString.length) return
      }
      if (obj.addPeerIds.length && tag == 'changeSource') addString = obj.addPeerIds
      const params = {
        addPeerIds: addString.join(','),
        deletePeerIds: obj.deletePeerIds.join(',') || '',
        deleteMainReceiver: obj.deleteMainReceiver
      }
      this.axios.post('/ccp/tvucc-device/timeLock/updateSync', params, { contentType: 'application/json;charset=UTF-8' })
        .then(res => {
          if (res.data.errorCode == '0x0') {
            // 这里新增或删除成功之后才可以加入timeLock
            this.selectReceiver = this.selectReceiver.concat(obj.addPeerIds)
            // 更新成功才可以加入用户行为记录
            this.goToBehavior()
          } else {
            this.selectRIng = false
            const errorInfo = res.data.errorInfo
            const obj = handleTimeLockTips(res.data.errorCode, errorInfo)
            this.$message({
              type: 'warning',
              message: obj.msg != '' ? obj.deviceInfo + this.$t(obj.msg) : errorInfo
            })
          }
        }).catch(() => {})
    },
    goToBehavior () {
      this.saveUserBehavior('', this.selectReceiver)
      // 如果timeLock开启时勾选，需要及时更新新加入的R进行timeLock
      if (this.selectRTimer) clearTimeout(this.selectRTimer)
      this.selectRTimer = setTimeout(() => {
        this.selectRIng = false
      }, 2000)
    },
    // 加这步的原因是list返回值顺序会变
    compareList (list) {
      const obj = {
        addPeerIds: [],
        deletePeerIds: [],
        deleteMainReceiver: false
      }
      if (this.selectReceiver.length == 0) {
        obj.addPeerIds = list
        return obj
      } else {
        // 这是增加
        if (this.selectReceiver.length < list.length) {
          list.map((v, i) => {
            const rid = v
            if (!this.selectReceiver.includes(rid)) {
              obj.addPeerIds.push(rid)
            }
          })
        } else {		// 这是减少
          this.selectReceiver.map((v, i) => {
            const rid = v
            if (!list.includes(rid)) {
              this.saveUserBehavior('delete', rid)
            }
          })
        }
        return obj
      }
    },
    setTimelock (enable) {
      if (this.otherOccurDevice.length && enable) {
        let userOccurObj = {}
        let userLen = this.otherOccurDevice.length
        this.otherOccurDevice.forEach(v => {
          if (userOccurObj[v.email]) {
            userOccurObj[v.email] += ` / <span class="occurDevice">${v.name}（${v.peerId}）</span>`
          } else {
            userOccurObj[v.email] = `${this.$t('lang.devices')}: <span class="occurDevice">${v.name}（${v.peerId}）</span>`
          }
        })
        let txtHtml = ''
        Object.keys(userOccurObj).forEach(key => {
          txtHtml += `<p>${userOccurObj[key]}${this.$t('lang.otherOccurDevices')} <span class="occurName">${key}</span></p></br>`
        })
        txtHtml += `<p>${userLen == 1 ? this.$t('lang.askUserRelease') : this.$t('lang.askUsersRelease')}</p>`
        this.$alert(`<div>${txtHtml}</div>`, '', {
          customClass: 'timtLockOccurBox',
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('lang.gotIt')
        })
        return
      }
      if (enable) {
        if (this.pn === 'home') {
          adAnalytice('setTimeLockHM')
        } else {
          adAnalytice('setTimeLockRC')
        }
        if (this.selectReceiver.length >= 2) {
          this.startTimelock()
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('lang.syncCanUseTitle')
          })
          this.isFreash = true
        }
      } else {
        this.stopTimelock()
        if (this.pn === 'hm') {
          adAnalytice('stopTimeLockHM')
        } else {
          adAnalytice('stopTimeLockRC')
        }
      }
    },
    startTimelock () {
      let mainPeerId = ''; let packPeerId = ''; let syncPeerIds = []
      this.lockedObj.receiver.forEach((item, index) => {
        const socketRItem = this.allRList.filter(v => v.peerId == item)
        if (socketRItem.length && socketRItem[0].status == '2') {
          if (index == 0) {
            mainPeerId = item
            packPeerId = socketRItem[0].livePeerId
          } else {
            syncPeerIds.push(item)
            syncPeerIds.push(socketRItem[0].livePeerId)
          }
        }
      })
      const params = {
        mainPeerId: mainPeerId,		// 主Rpeerid(第一个R为主R)
        packPeerId: packPeerId,		// 所有辅R的peerid,包括source,用逗号隔开
        syncPeerIds: syncPeerIds
      }
      this.axios.post('/ccp/tvucc-device/timeLock/sync', params, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        this.isFreash = true
        if (res.data.errorCode == '0x0') {
          this.dragEnd('', 'enter')
          this.$message({
            message: this.$t('lang.syncSuccess'),
            type: 'success'
          })
        } else {
          const errorInfo = res.data.errorInfo
          const obj = handleTimeLockTips(res.data.errorCode, errorInfo)
          this.$message({
            type: 'warning',
            message: obj.msg != '' ? obj.deviceInfo + this.$t(obj.msg) : errorInfo
          })
        }
      }).catch(() => {})
    },
    stopTimelock () {
      this.axios.post('/ccp/tvucc-device/timeLock/unSync', {}).then(res => {
        this.isFreash = true
        if (res.data.errorCode == '0x0') {
          this.$message({
            message: this.$t('lang.removeSyncSuccess'),
            type: 'success'
          })
        } else {
          const errorInfo = res.data.errorInfo
          const obj = handleTimeLockTips(res.data.errorCode, errorInfo)
          this.$message({
            type: 'warning',
            message: obj.msg != '' ? obj.deviceInfo + this.$t(obj.msg) : errorInfo
          })
        }
      }).catch(() => {})
    },
    // 获取R下拉列表
    async getRList () {
      await this.axios.post('/ccp/tvucc-user/userDevice/listReceiverByTimeLockFeature', this.params, { contentType: 'application/json;charset=UTF-8' }).then(res => {
        if (res.status == 200) {
          const rList = res.data.result
          this.allRId = []
          rList.map(v => {
            this.allRId.push(v.peerId)
          })
          this.rList = rList // R下拉数据
          if (this.behaviorList.length) this.getAllRid(this.behaviorList, rList)
        } else {
          this.$message({
            type: 'warning',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {})
    },
    handleSelectReceiver (peerId) {
      // 如果是删除，需要将selectReceiver中的rId移除掉
      const selectReceiverCopyList = JSON.parse(JSON.stringify(this.selectReceiver))
      const index = selectReceiverCopyList.findIndex(v => v == peerId)
      if (index >= 0) this.selectReceiver.splice(index, 1)
    },
    msgCenter (tag, peerId, type) {
      if (!this.synchronizedObj.synchronized) return
      const deleteObj = {
        addPeerIds: [],
        deletePeerIds: [peerId],
        deleteMainReceiver: false
      }
      if (tag == 'delete') { // 正常删除
        deleteObj.deletePeerIds = [peerId]
        deleteObj.deleteMainReceiver = false
        this.handleSelectReceiver(peerId)
        this.updateTimeLock(deleteObj)
        return
      }
      const h = this.$createElement
      let targetObj = null
      if (tag == 'mainMsg') { // 删除主TimeLock
        targetObj = h('p', { style: 'font-size: 14px;' }, [
          h('p', { style: 'display: block;width:425px;' }, `${this.$t('lang.removeMainRTips')}`),
          h('p', null, `${this.$t('lang.StillRemove')}`)
        ])
      } else if (tag == 'oneMsg') { // 当前数量少于等于2
        targetObj = h('p', { style: 'font-size: 14px;' }, [
          h('p', null, `${this.$t('lang.OneReceiverTip')}`),
          h('p', null, `${this.$t('lang.StillRemove')}`)
        ])
      }
      this.$msgbox({
        title: '',
        message: targetObj,
        showCancelButton: true,
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.Cancel'),
        callback: action => {
          if (action === 'confirm') {
            // 确认后解除整个TimeLock，并移除掉当前的R
            if (tag == 'oneMsg' || tag === 'mainMsg') {
              deleteObj.deletePeerIds = [peerId]
              deleteObj.deleteMainReceiver = tag == 'mainMsg'
              this.handleSelectReceiver(peerId)
              this.updateTimeLock(deleteObj)
            }
          } else {
            this.selectRIng = false
          }
        }
      })
    },
    saveUserBehavior (key, list, module = 'rList', flag) {
      this.selectRIng = true
      // 当TimeLock开启时，需要添加验证
      if (this.synchronizedObj.synchronized && key == 'delete' && module === 'rList') {
        // key 是delete的时候list为单独的Rid
        let type = 'delete'
        if (this.selectReceiver.length <= 2) type = 'oneMsg'
        if (list.toLowerCase() == this.selectReceiver[0].toLowerCase()) type = 'mainMsg'
        this.msgCenter(type, list)
        return
      }
      let array = []; let whichModule = ''; let options = {}
      module === 'bitrateDelay' ? whichModule = 'liveParam' : whichModule = 'multiR'
      if (module === 'rList') {
        if (key === 'delete') {
          // key 是delete的时候list为单独的Rid
          const selectReceiverCopyList = JSON.parse(JSON.stringify(this.selectReceiver))
          for (var i = 0; i < selectReceiverCopyList.length; i++) {
            if (selectReceiverCopyList[i].includes(list)) {
              this.selectReceiver.splice(i, 1)
            } else {
              let obj = {}
              obj.pid = selectReceiverCopyList[i]
              array.push(obj)
            }
          }
        } else {
          list.map((v, i) => {
            let obj = {}
            obj.pid = v
            array.push(obj)
          })
        }
      }

      if (module === 'bitrateDelay') {
        options.delay = flag === 'enter' ? this.synchronizedObj.inputDelay * 1000 : this.synchronizedObj.delay * 1000
        options.bitrate = flag === 'enter' ? this.synchronizedObj.inputBitrate : this.synchronizedObj.bitrate
      }

      const parmas = {
        pn: 'hm',
        content: module == 'bitrateDelay' ? JSON.stringify(options) : JSON.stringify(array),
        remark: whichModule
      }
      this.axios.post('/ccp/tvucc-user/userBehavior/set', parmas).then(res => {
        if (res.status == 200) {
          this.isFreash = false
          this.selectRIng = false
          setTimeout(() => {
            this.isFreash = true
          }, 1500)
        }
      }).catch(() => {})
    },
    dragEnd (val, key, enterKey) {
      this.isFreash = false
      const parmas = {
        peerIds: this.selectReceiver.join()
      }

      if (key === 'enter') {
        parmas.bitrate = Number(this.synchronizedObj.inputBitrate)
        parmas.delay = Number(this.synchronizedObj.inputDelay) * 1000
      } else {
        if (key === 'bitrate') {
          parmas.bitrate = val
          parmas.delay = Number(this.synchronizedObj.delay) * 1000
        } else if (key === 'delay') {
          parmas.bitrate = Number(this.synchronizedObj.bitrate)
          parmas.delay = val * 1000
        }
      }

      if (key === 'bitrate') {
        if (this.pn === 'hm') {
          adAnalytice('setTimeLockBitrateBySliderHM')
        } else {
          adAnalytice('setTimeLockBitrateBySliderRC')
        }
      } else if (key === 'delay') {
        if (this.pn === 'hm') {
          adAnalytice('setTimeLockDelayBySilderHM')
        } else {
          adAnalytice('setTimeLockDelayBySilderRC')
        }
      }
      if (enterKey === 'bitrate') {
        if (this.pn === 'hm') {
          adAnalytice('setTimeLockDelayByInputHM')
        } else {
          adAnalytice('setTimeLockDelayByInputRC')
        }
      } else if (enterKey === 'delay') {
        if (this.pn === 'hm') {
          adAnalytice('selectTimeLockReceiverByDropHM')
        } else {
          adAnalytice('selectTimeLockReceiverByDropRC')
        }
      }

      this.axios.post('/ccp/tvucc-device/timeLock/setBitrateAndDelay', parmas).then(res => {
        if (res.status === 200) {
          this.saveUserBehavior('', '', 'bitrateDelay', key)
        } else if (res.data.errorCode == '81100901') {
          let _str = 'Set Failed, '
          res.data.result.forEach((v) => {
            const name = v.name
            const msg = v.msg
            _str += name + ':' + msg + ','
          })
          const index = _str.lastIndexOf(',')
          _str = _str.slice(0, index)
          this.$message({
            type: 'warning',
            message: _str
          })
        }
        setTimeout(() => {
          this.isFreash = true
        }, 1000)
      }).catch(() => {})
    },
    changeFreash (val) {
      this.isFreash = val
    },
    // create new websocket
    createNewRWebsocket () {
      this.sendMsgWebsocket('ReceiverDataMutilControl', '2152857600', '101', 1000)
      this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', 1000)
    },
    // send get R info msg
    sendMsgWebsocket (type, categoryId, operationType, time) {
      let typeVal = ''
      if (type != 'VolumeChart') typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: this.selectReceiver.join(',')
        },
        isOnce: true,
        time: time || 1000,
        key: typeVal || type,
        success: res => {
          const pageRListInfo = this.pageRWebsocket.sendMsgs[type.charAt(0).toLowerCase() + type.slice(1)]
          if (pageRListInfo && !this.selectRIng) {
            pageRListInfo.message = {
              categoryId: categoryId,
              operationType: operationType,
              rid: this.selectReceiver.join(',')
            }
          }
          if (type == 'ReceiverDataMutilControl') {
            this.updateDeviceDetailData('deviceListReceiverDataData', 'DEVICELIST_RECEIVERDATADATA', res)
          } else {
            this.updateDeviceDetailData(`deviceList${type}Data`, `DEVICELIST_${type.toUpperCase()}DATA`, res)
          }
        }
      })
    },
    updateDeviceDetailData (deviceData, fn, data) {
      let deviceDetailArr = []
      let deviceDetailIdArr = []
      this.States[deviceData].map((v, i) => {
        if (this.selectReceiver.includes(v.rid)) {
          deviceDetailArr.push(v)
          deviceDetailIdArr.push(v.rid)
        }
      })
      if (deviceDetailIdArr.includes(data.rid)) {
        deviceDetailArr[deviceDetailIdArr.indexOf(data.rid)] = data
      } else {
        deviceDetailArr.push(data)
      }
      this[fn](deviceDetailArr)
    },
    changeVal (flag) {
      const bitrate = Number(this.synchronizedObj.inputBitrate)
      const delay = Number(this.synchronizedObj.inputDelay)
      if (typeof bitrate != 'number' || typeof delay != 'number') {
        this.$message({
          type: 'warning',
          message: this.$t('lang.rightNumber')
        })
        return
      }
      if (flag === 'bitrate') {
        // 超出范围提示
        if (bitrate < 100 || bitrate > 10240) {
          this.$message({
            type: 'warning',
            message: this.$t('lang.bitrateDelay', [100, 10240])
          })
          return
        }
        this.dragEnd(bitrate, 'enter', 'bitrate')
      } else {
        // 超出范围提示
        if (delay < 0.5 || delay > 50) {
          this.$message({
            type: 'warning',
            message: this.$t('lang.bitrateDelay', [0.5, 50])
          })
          return
        }
        this.dragEnd(delay, 'enter', 'delay')
      }
    },
    deletePeerId (peerId) {
      this.lockedObj.pack.map((v, i) => {
        const currentPeerid = v
        if (currentPeerid === peerId) {
          this.lockedObj.pack.splice(i, 1)
          const rid = this.tRInfoMap[currentPeerid]
          this.lockedObj.receiver.map((v, i) => {
            if (rid === v) this.lockedObj.receiver.splice(i, 1)
          })
        }
      })
    },
    close () {
      // 埋点统计
      if (this.pn === 'home') {
        adAnalytice('closeMultiControlHM')
      } else {
        adAnalytice('closeMultiControlRC')
      }

      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', 'ReceiverDataMutilControl')
        this.$createWebsocket.pageRWebsocket('stop', 'currentRInfo')
      }
      Bus.$emit('createNewWs', this.rid)
      this.stopSocket()
      Bus.$emit('selectOtherR', '', 'notChangeRStatus')
    },
    stopSocket () {
      if (this.pageRequest.successObj && this.pageRequest.interval) {
        if (this.pageRequest.successObj.timeLockMultiR && this.pageRequest.interval.timeLockMultiR) {
          this.pageRequest.stop('timeLockMultiR')
        }
        if (this.pageRequest.successObj.checkDevieTimeLockStatus && this.pageRequest.interval.checkDevieTimeLockStatus) {
          this.pageRequest.stop('checkDevieTimeLockStatus')
        }
      }
    }
  },
  beforeDestroy () {
    this.stopSocket()
    if (this.pageRequest) {
      this.pageRequest.stop('rList_info')
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/css/rcAdaptive/index.less';
::v-deep .multipe-select-box .title {
  background: #363636;
}
.mutiControls {
  background: #252525;
  .top {
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: left;
    padding-left: 16px;
    font-weight: bolder;
    font-size: 16px;
    background: #1b1b1b;
    .ponter {
      cursor: pointer;
    }
    i {
      color: #33ab4f;
      margin-right: 5px;
    }
  }
  .body-left {
    width: 353px;
    height: 868px;
    float: left;
    text-align: center;
    background-color: #252525;
    z-index: 200;
    padding: 0 16px;
    margin-top: 16px;
    border-right: 2px solid #444;
    &.bg333 {
      background: #333;
    }
    .listBox {
      position: relative;
      z-index: 1005;
    }
  }
  .body-right {
    width: calc(100% - 353px);
    float: left;
    background-color: #252525;
    padding: 16px;
  }
  ::v-deep .multiInput .el-input__inner {
    padding: 0 8px;
  }
  .timeLockModule {
    text-align: left;
    .checkModule .failedTips {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-indent: 10px;
      color: #EC5050;
    }
    .timelockTips {
      color: #666;
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 16px 0;
    }
    .rlist {
      height: 32px;
      line-height: 32px;
    }
    .settingItem {
      margin: 16px 0;
      .sliderBox {
        ::v-deep .el-slider {
          width: calc(100% - 115px);
          display: inline-block;
        }
      }
      .multiInput {
        width: 94px;
        margin: 5px 0;
        float: right;
      }
    }
  }
  .rtcVideoModule {
    /* border: 1px dashed #666; */
    display: flex;
    flex-flow: wrap;
    position: relative;
    .videoListBox {
      position: absolute;
    }
    .rtcBox {
      border: 1px dashed #666;
      width: 354px;
      margin-right: 12px;
      margin-bottom: 9px;
      height: 275px;
      float: left;
      .mainText {
        text-align: center;
        margin-top: 35%;
        color: #666;
        font-size: 16px;
      }
      .wh_parent {
        height: 100%;
        width: 100%;
      }
    }
    .rtcBox.noBoreder {
      border: none;
    }
  }
}
</style>
