<template>
  <div class="rtcVideoBox">
    <div class="rtcVideo">
      <div class="hide">{{socketItemData}}</div>
      <div class="rInfo hide">{{KernelState}}</div>
      <div class="rInfo hide">{{ReceiverInfo}}</div>
      <div
        class="receiverInfo clearfix"
        v-show="KernelState.WorkMode">
        <div
          class="text f_left"
          v-if="selectSourceRSocketData.status == 2">{{$t('lang.live')}}</div>
        <div class="info f_right text_right">
          <div class="quailty">{{this.ReceiverInfo.QualityMeasureOnGUI?this.ReceiverInfo.QualityMeasureOnGUI+'kbps':0+'kbps'}}</div>
          <div class="errorinfo">{{this.ReceiverInfo.ErrorRateLastSec?this.ReceiverInfo.ErrorRateLastSec+'%':0+'%'}}</div>
        </div>
      </div>
      <div
        class="closeIconBox"
        @click="deleteSelectR">
        <i class="el-icon el-icon-close"></i>
      </div>
      <video-module
        ref="changeVoice"
        :needShowMuteIcon="false"
        :clearSelectR="true"
        height="100%"
        width='100%'
        :useOldR="false"
        :source="State.receiverRList[rid]"
        deviceType="R"
        :rid="rid"
        :isMultiContol='true'></video-module>
    </div>
    <div class="operationBox">
      <div class="operationBox-top clearfix">
        <span
          class="RSyncStatus dots iconfont i-timelock"
          :class="returnClass('R')"
          :title="returnTitle('R')"></span>
        <div class="hide">{{synchronized}}</div>
        <div
          class="receiverName f_left"
          :class="judgeFontColor">{{socketItemData.name}}</div>
        <div class="options f_right">
          <i
            class="iconfont fullScreenIcon"
            @click.stop="showWebrPage"
            :class="showIconClass"></i>
          <i
            class="iconfont voiceIcon"
            :class="showVideoMuteIcon()"
            @click.stop="changeVideoIcon($event)"></i>
          <i
            class="iconfont infoIcon rinfoIcon"
            :class="showIconClass"
            @click.stop="showRInfo($event)">
            <!-- position: unset!important; transform: rotate(-180deg); -->
            <el-popover
              placement="top-start"
              width="220"
              popper-class="mutilControlPopper"
              v-model="visible">
              <div class="rInfoBox clearBox">
                <div class="f_left w_50">{{$t('lang.TBattery')}}</div>
                <div class="f_left greenColor m_r_5">{{KernelState.CurrentTPackBatteryRaw?KernelState.CurrentTPackBatteryRaw.BAT1VAL + '%':'0%'}}</div>
              </div>
              <div class="rInfoBox clearBox">
                <div class="f_left m_r_5">2</div>
                <div class="f_left greenColor">{{KernelState.CurrentTPackBatteryRaw?KernelState.CurrentTPackBatteryRaw.BAT2VAL + '%':'0%'}}</div>
              </div>
              <div class="rInfoBox clearBox">
                <div class="f_left w_55">{{$t('lang.TInput')}}</div>
                <div class="f_left greenColor">{{KernelState.CurrentTInputType?KernelState.CurrentTInputType.ReadableName:'N/A'}}</div>
              </div>
              <div class="rInfoBox clearBox">
                <div class="f_left w_55">{{$t('lang.TFormat')}}</div>
                <div class="f_left greenColor">{{KernelState.CurrentTFormat?KernelState.CurrentTFormat.ReadableName:'N/A'}}</div>
              </div>
              <div class="rInfoBox clearBox">
                <div class="f_left w_55">{{$t('lang.RFormat')}}</div>
                <div class="f_left greenColor">{{KernelState.CurrentOutputFormat2?KernelState.CurrentOutputFormat2.ReadableName:'NTSC'}}</div>
              </div>
            </el-popover>
          </i>
        </div>
      </div>
      <div class="operationBox-body">
        <span
          class="TSyncStatus dots iconfont i-timelock"
          :class="returnClass('T')"
          :title="returnTitle('T')"></span>
        <SelectSourceR
          :sourceItem="selectSourceRSocketData"
          fromPage="multilControl"
          :key="rid"
          @getSelectInfo="getSelectInfo"
          :hadSelectDevice="isSelectNewR"
          :timeLocked="synchronized"
          :timeLockPage="true"
          type="R"
          class="f_left"
          @changeHadSelectDevice="changeHadSelectDevice"></SelectSourceR>
        <videoButton
          flag="R"
          :sourceItem="selectSourceRSocketData"
          :hadSelectDevice="isSelectNewR"
          :deviceList="returnTRId"
          @changeHadSelectDevice="changeHadSelectDevice"
          :timeLocked="synchronized"
          :timeLockPage="true"
          class="f_right">
        </videoButton>
        <div class="hide">{{returnTRId}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import videoModule from '@/components/VideoC'
import SelectSourceR from '@/components/SelectSourceR'
import videoButton from '@/components/VideoButton'
import Tool from '@/assets/js/utils.js'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'

export default {
  props: {
    rid: {
      type: String,
      default: ''
    },
    pn: {
      type: String,
      default: 'hm'
    },
    synchronized: {
      type: Boolean,
      default: false
    },
    synchronizStatus: {
      type: Array,
      default: function () {
        return []
      }
    },
    socketItemData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    canSetTimelockRList: {
      type: Array,
      default: function () {
        return []
      }
    },
    firstOne: {
      type: Boolean,
      default: false
    },
    page: {
      type: String,
      default: 'hm'
    }
  },
  components: {
    SelectSourceR,
    videoButton,
    videoModule
  },
  computed: {
    ...mapState({
      State: state => state,
      ReceiverInfoObj1: state => state.ReceiverInfoObj,
      GetKernelStateObj1: state => state.GetKernelStateObj
    }),
    ...mapState('common', {
      States: state => state
    }),
    KernelState () {
      const rList = this.States.deviceListCurrentRInfoData
      let data = {}
      rList.map(v => {
        const currentRid = v.rid
        if (this.rid == currentRid && Tool.isJSON(v.data)) {
          data = JSON.parse(v.data)
        }
      })
      return data
    },
    ReceiverInfo () {
      const rList = this.States.deviceListReceiverDataData
      let data = {}
      rList.map(v => {
        const currentRid = v.rid
        if (this.rid == currentRid && Tool.isJSON(v.data)) {
          data = JSON.parse(v.data)
        }
      })
      return data
    },
    returnTRId () {
      let _str = ''
      if (this.selectSourceId) {
        _str += this.rid + ',' + this.selectSourceId
      }
      return _str
    },
    showIconClass () {
      if (this.socketItemData.status == 0) {
        return 'disabled'
      } else {
        return ''
      }
    },
    judgeFontColor () {
      if (this.socketItemData.status == 0) {
        return 'grayColor'
      } else {
        return ''
      }
    }
  },
  watch: {
    socketItemData: function (newVal, oldVal) {
      this.livePeerId = newVal.livePeerId
      this.selectSourceRSocketData = newVal
      this.selectSourceRSocketData.random = Math.random()
    }
  },
  data () {
    return {
      picture: require('@/assets/img/placeholder.png'),
      visible: false,
      isSelectNewR: false,
      selectSourceId: '',
      livePeerId: this.socketItemData.livePeerId || '',
      init: true,
      mutedFlag: true,
      selectSourceRSocketData: this.socketItemData // 为selectSourceR特意写的参数
    }
  },
  mounted: function () {
    // this.getReceiverData();
    this.createListen()
  },
  methods: {
    createListen () {
      document.addEventListener('click', (e) => {
        if (e.target && typeof (e.target.className.includes) == 'function') {
          if (!e.target.className.includes('rinfoIcon')) {
            this.visible = false
          }
        }
      })
    },
    getReceiverData () {
      if (this.State.selectRId != this.rid) {
        this.pageRWebsocket.send({
          message: { categoryId: '2152857600', operationType: '102', rid: this.rid },
          time: 1000,
          key: 'getQueryKennel',
          success: res => {}
        })
      }
    },
    deleteSelectR () {
      if (this.page === 'home') {
        adAnalytice('cancelSelectTimLockReceiverByClickBtnHM')
      } else {
        adAnalytice('cancelSelectTimLockReceiverByClickBtnRC')
      }
      this.$emit('saveUserBehavior', 'delete', this.rid)
    },
    showWebrPage () {
      if (this.State.receiverRList[this.rid] && this.socketItemData.status != 0) {
        const rIp = this.State.receiverRList[this.rid].ip
        const rPort = Number(this.State.receiverRList[this.rid].port) + 200
        const version = this.State.receiverRList[this.rid].version
        const url = version.includes('7.8') ? `http://${rIp}:${rPort}` : `http://${rIp}:${rPort}/webr`
        window.open(url)
      }
      if (this.page === 'hm') {
        adAnalytice('showWebPageHM')
      } else {
        adAnalytice('showWebPageRC')
      }
    },
    showRInfo () {
      if (this.socketItemData.status == 0) return
      this.visible = !this.visible
      if (this.page === 'hm') {
        adAnalytice('showReceiverInfoHM')
      } else {
        adAnalytice('showReceiverInfoRC')
      }
    },
    returnTitle (key) {
      let text = this.firstOne ? this.$t('lang.serverTimeLocking') : this.$t('lang.clientTimeLocking')
      if (this.socketItemData.userEmail && key == 'R') {
        text = text + this.$t('lang.otherOccur', { userEmail: this.socketItemData.userEmail })
        return text
      }
      if (this.socketItemData.packUserEmail && key == 'T') {
        text = text + this.$t('lang.otherOccur', { userEmail: this.socketItemData.packUserEmail })
        return text
      }
      return text
    },
    returnClass (key) {
      let obj = {}
      obj.hideDot = key === 'R' ? !this.socketItemData.userEmail : !this.socketItemData.packUserEmail
      obj.otherOccur = key === 'R' ? this.socketItemData.userEmail : this.socketItemData.packUserEmail
      this.synchronizStatus.map((v, i) => {
        const peerId = v.peerId
        if (key === 'R') {
          if (peerId == this.rid) {
            obj.hideDot = false
            switch (v.status) {
              case 'success':
                obj.bgGreen = true
                break
              case 'excuting':
              case 'sending':
                obj.bgRed = true
                break
              case 'failed':
                obj.bgGray = true
                break
            }
          }
        } else {
          if (peerId == this.livePeerId) {
            obj.hideDot = false
            switch (v.status) {
              case 'success':
                obj.bgGreen = true
                break
              case 'excuting':
              case 'sending':
                obj.bgRed = true
                break
              case 'failed':
                obj.bgGray = true
                break
            }
          }
        }
      })
      if (this.firstOne) obj.mainTimeLock = true
      return obj
    },
    getSelectInfo (data) {
      this.selectSourceId = data.deviceList.split(',')[0]
    },
    changeHadSelectDevice (val) {
      this.isSelectNewR = val
    },
    showVideoMuteIcon () {
      let _str = ''
      if (this.mutedFlag) {
        _str = 'mutedIcon'
      } else {
        _str = 'noMutedIcon'
      }

      if (this.socketItemData.status != 2) {
        this.mutedFlag = true
        _str = 'mutedIcon disabled'
      }
      return _str
    },
    changeVideoIcon (event) {
      if (this.socketItemData.status != 2) return
      this.mutedFlag = !this.mutedFlag
      this.$refs.changeVoice.changeVideoVoice(this.mutedFlag)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-popover {
  background: #444;
  border: none;
  color: #dbdbdb;
  min-width: 190px;
}
::v-deep .selectSource .el-input {
  margin: 0;
  font-size: 12px;
}
::v-deep .el-input .el-input__suffix {
  line-height: 28px;
}
::v-deep .el-select .el-input .el-select__caret {
  line-height: inherit;
  height: 0;
}

::v-deep .el-select-dropdown {
  ul {
    width: 168px !important;
    .name {
      max-width: 95px !important;
    }
  }
}
.rtcVideoBox {
  .rtcVideo {
    width: 100%;
    height: 199px;
    position: relative;
    .url {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: orange;
      width: 90%;
    }
    .receiverInfo {
      position: absolute;
      top: 16px;
      z-index: 5;
      width: 100%;
      .text {
        margin-left: 16px;
        width: 52px;
        height: 24px;
        background: rgba(255, 51, 51, 1);
        border-radius: 2px;
        opacity: 0.76;
        text-align: center;
        line-height: 24px;
      }
      .info {
        font-size: 14px;
        font-weight: bold;
        margin-right: 12px;
      }
    }
    .closeIconBox {
      width: 20px;
      height: 20px;
      background: #444444;
      border-radius: 50%;
      position: absolute;
      right: -6px;
      z-index: 4;
      top: -9px;
      display: none;
      cursor: pointer;
      text-align: center;
      .el-icon-close {
        color: #c84242;
        font-weight: bold;
      }
    }
  }
  .rtcVideo:hover .closeIconBox {
    display: block;
  }
  .operationBox {
    padding: 10px 8px;
    background: #08130a;
    .dots {
      margin-right: 5px;
      float: left;
    }
    .hideDot {
      visibility: hidden;
    }
    .TSyncStatus {
      margin-top: 5px;
    }
    .operationBox-top {
      height: 18px;
      line-height: 18px;
      .options {
        font-size: 16px;
        position: relative;
        z-index: 10;
        .fullScreenIcon {
          transform: rotate(90deg);
          position: absolute;
          right: 50px;
          top: 6px;
          &:before {
            content: '\e65d';
            margin-right: 12px;
          }
        }
        .mutedIcon:before {
          content: '\e615';
          margin-right: 12px;
          color: #ff3333;
        }
        .infoIcon {
          transform: rotate(180deg);
          &:before {
            content: '\e693';
          }
        }
        .fullScreenIcon.disabled {
          cursor: not-allowed;
          color: gray;
        }
        .voiceIcon.disabled:before,
        .infoIcon.disabled:before {
          cursor: not-allowed;
          color: gray;
        }
        .noMutedIcon:before {
          content: '\e613';
          margin-right: 12px;
          color: #fff;
        }
      }
    }
    .operationBox-body {
      height: 32px;
      margin-top: 5px;
      .selectBox {
        width: 185px;
      }
      .selectSource {
        width: 65%;
      }
      ::v-deep .el-button {
        min-width: 60px!important;
      }
    }
  }
}
</style>
